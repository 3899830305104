#container {
  padding: 10px;
  background-color: red

}

#container ul {
  list-style-type: none;
  text-align: center;
  color: red;
}

#container ul li {
  display: inline-block;
  width: 160px;
  margin: 10px;
}

#container ul li img {
  width: 100px;
}

.button-info {
  margin: 10px;
}

.dx-popup-content p {
  margin-bottom: 100px;
  margin-top: 0;
}

#textBlock {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.6em;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  width: 300px;
}
.a {
  display: inline;
}